
import {createApp} from 'vue'
import App from './App.vue'
import store from './store/index'
import Vuex from 'vuex'
import ElementUI from 'element-plus';
import httpServe from '../plugins/http.js'
import router from './router/index.js'
import Meta from "vue-meta";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import "swiper/swiper-bundle.css";
import 'element-plus/dist/index.css'
import './static/css/common.css'

import './static/js/qqMap'
import './static/js/hm.baidu'

const app = createApp(App)

app.use(Vuex)
app.use(ElementUI,{
	locale: zhCn
})
app.use(router)
app.use(store)
app.use(VueAwesomeSwiper)
app.config.globalProperties.$http = httpServe
app.config.globalProperties.$meta = Meta

app.mount('#app')