const maindata = {
  data: [
    "https://files.skg.com/skg.com/20200910/69cffb6ccbee4d329324b6305d2a3072.jpg",
    "https://files.skg.com/skg.com/20200910/0d435a0419ae437ab9ff9f9eb1630f10.jpg",
    "https://files.skg.com/skg.com/20200910/ae27fd24c7074aa0bc21fadc8a224250.jpg",
    "https://files.skg.com/skg.com/20200910/71c2f49782ef46b9a888defdc8cbbfe6.jpg",
    "https://files.skg.com/skg.com/20200910/8b5e86023f13476d8e5c0579b54edeec.jpg",
    "https://files.skg.com/skg.com/20200910/d9247ff45a864694a1a8e302ffd2c8c9.jpg",
    "https://files.skg.com/skg.com/20200910/9a739631b34140018b219eab96740fc9.jpg",
    "https://files.skg.com/skg.com/20200910/5b94772314b341168a243ae7faa808c9.jpg",
    "https://files.skg.com/skg.com/20200910/83115de165524dbd8a0909e781849a8b.jpg",
    "https://files.skg.com/skg.com/20200910/d14a685522e348ccb43d1268812d3db8.jpg",
    "https://files.skg.com/skg.com/20200910/5a1f7e63a19244f5ac07f171a8e4e9b3.jpg",
    "https://files.skg.com/skg.com/20200910/e09e0215600649ef8ced3bff648bbfb2.jpg",
    "https://files.skg.com/skg.com/20200910/ed0b33e6a18c40c687795fdc2539d76e.jpg",
    "https://files.skg.com/skg.com/20200910/c66f947c5d744df78016a17b586d7ae9.jpg",
    "https://files.skg.com/skg.com/20200910/880e67a1231443a583b7f29f8de34142.jpg",
    "https://files.skg.com/skg.com/20200910/bb078742d9034582838d5bfb74588300.jpg",
    "https://files.skg.com/skg.com/20200910/3abbccdf880948bd9cd0a2dbe2eaa403.jpg",
    "https://files.skg.com/skg.com/20200910/00ecec967f4b42e786a705845f7857e3.jpg",
    "https://files.skg.com/skg.com/20200910/706d79fcab8e43f3a4d83e66581f60e8.jpg",
    "https://files.skg.com/skg.com/20200910/9113f53664664e6c943e1e638b94d988.jpg",
    "https://files.skg.com/skg.com/20200910/880a3f338a3d424d872c57ab7fbb5eca.jpg",
    "https://files.skg.com/skg.com/20200910/3a92b73cfdab4a1292bd7841fc7f49f2.jpg",
    "https://files.skg.com/skg.com/20200910/ee07b40a9b77446a94c549fc4444e499.jpg",
    "https://files.skg.com/skg.com/20200910/edf5724c57f640ecb2677092f61c7d32.jpg",
],
};
export default maindata;
