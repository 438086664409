<template>
  <div class="banner">
    <div class="bannerTop">
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#000"
        text-color="#fff"
        active-text-color="#42d7c8"
        style="height: 21px; padding-left: 20px; font-size: 14px"
      >
        <el-sub-menu
          :index="index + 1 + ''"
          v-for="(item, index) in menuList"
          :key="index"
        >
          <template #title>{{ item.title }}</template>
          <el-menu-item
            :index="index + 1 + '-' + (chidrenIndex + 1)"
            v-for="(childrenItem, chidrenIndex) in item.children"
            :key="chidrenIndex"
          >
            <router-link
              :to="{
                name: childrenItem.path,
                params: { index: childrenItem.params },
              }"
              v-if="childrenItem.path.length < 20"
              >{{ childrenItem.name }}</router-link
            >
            <div
              @click="openView(childrenItem.path)"
              v-if="childrenItem.path.length > 20"
            >
              {{ childrenItem.name }}
            </div>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
    <div
      class="logo mobileLogo"
      :class="{ open: openburger }"
      @click="clkHome()"
      id="logo"
      v-show="!openburger"
    >
      <img
        src="https://img.skg.com/skg.com/20200723/529c7a5ad3bb4f3495046f0082188e16.png"
        alt
      />
    </div>
    <img
      class="mobileLangIcon"
      @click="goto"
      src="https://files.skg.com/skg/2021-10-25/7f3b7da6-7ae7-4af8-a5ba-4229b5987ddc.png"
      alt=""
    />
    <div class="pcList">
      <div
        class="logo"
        :class="{ open: openburger }"
        @click="clkHome()"
        id="logo"
        v-show="!openburger"
      >
        <img
          src="https://img.skg.com/skg.com/20200723/529c7a5ad3bb4f3495046f0082188e16.png"
          alt
        />
      </div>
      <div
        class="pcListChild"
        v-for="(item, index) in proList"
        :key="index"
        :class="index > proList.length + 1 ? 'childTxt' : ''"
        @mouseover="pcShowDetail(index)"
        @mouseleave="pcHideDetail(index)"
      >
        <span class="pcListChildTit">{{ item.title }}</span>
      </div>
      <div class="pcListChild childTxt">
        <router-link to="/sooth" no-prefetch class="pcListChildTit"
          >技术原理</router-link
        >
      </div>
      <div class="pcListChild childTxt">
        <img
          class="language-icon"
          src="https://files.skg.com/skg/2021-10-25/7f3b7da6-7ae7-4af8-a5ba-4229b5987ddc.png"
          @click="goto"
          alt="langeuage"
          srcset=""
        />
      </div>
    </div>
    <div class="menuInputBox" v-show="openburger">
      <input
        type="text"
        class="menuInput"
        placeholder="K6"
        v-model="menuInput"
        @keyup.enter="search"
        @keyup="closes"
      />
      <span id="serText" @click="search">搜索</span>
    </div>
    <ul id="searchList" v-if="showSearch">
      <li
        v-for="(item, index) in searchList"
        :key="index + '1'"
        @click="openProDetail(item.type)"
      >
        {{ item.name }}
      </li>
      <li
        v-for="(item, index) in searchList2"
        :key="index + '2'"
        @click="openNewsdetail(item.fileName)"
      >
        {{ item.title }}
      </li>
    </ul>
    <ul class="level" :class="{ open: openburger }" ref="menu">
      <li v-for="(item, index) in proList" :key="index + 'a'">
        <div class="titleBox" @click="showPro(index, 'proDel')">
          <img
            :src="item.titleImg"
            alt=""
            class="titleImg"
            v-if="item.titleImg"
          />
          <span v-if="item.title" class="titleTxt">{{ item.title }}</span>
          <img src="@/static/img/open.png" alt="" class="openImg" />
        </div>
        <div class="proDel">
          <div
            class="proBox"
            v-for="(childrenItem, childrenIndex) in item.children"
            :key="childrenIndex"
            @click="openProDetail(childrenItem.path, childrenItem.params)"
          >
            <router-link
              :to="{
                path: childrenItem.path,
                query: { index: childrenItem.params },
              }"
            >
              <img :src="childrenItem.img" alt="" class="chidrenImg" />
              <p class="childrenName">{{ childrenItem.name }}</p>
            </router-link>
          </div>
        </div>
      </li>
      <li v-for="(item, index) in routerList" :key="index + 'b'">
        <router-link :to="item.path">
          <span class="titleTxt">{{ item.title }}</span>
          <img src="@/static/img/open.png" alt="" class="openImg" />
        </router-link>
      </li>
      <li v-for="(item, index) in childrenList" :key="index + 'c'">
        <div class="childrenTit" @click="showDel(index, 'routerBox')">
          <span class="titleTxt">{{ item.title }}</span>
          <img
            src="@/static/img/open.png"
            alt=""
            class="openImg childrenOpenImg"
          />
        </div>

        <div class="routerBox">
          <div
            class="detailBox"
            v-for="(detailItem, detailIndex) in item.childrenTxt"
            :key="detailIndex"
          >
            <router-link :to="detailItem.path">
              <span>{{ detailItem.name }}</span>
              <img src="@/static/img/grayTo.png" alt="" class="toImg" />
            </router-link>
          </div>
        </div>
      </li>
    </ul>
    <div
      class="pcProList"
      v-show="pcProListShow"
      @mouseover="showList"
      @mouseleave="hideList"
    >
      <div class="pcProMenu">
        <div
          class="pcProBox"
          v-for="(childrenItem, childrenIndex) in proList[listIndex].children"
          :key="childrenIndex + 1"
          @click="openProDetail(childrenItem.path, childrenItem.params)"
        >
          <router-link
            :to="{
              path: childrenItem.path,
              query: { index: childrenItem.params },
            }"
          >
            <img :src="childrenItem.img" alt="" class="pcChidrenImg" />
            <p class="pcChildrenName">{{ childrenItem.name }}</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="burger" @click.stop.prevent="openMenu">
      <burger :isClose="openburger" />
    </div>
    <Language v-if="show_lang" />
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import regMenu from "../login_menu.vue";
import burger from "./burger-bt.vue";
import Language from "./language.vue";
export default {
  name: "skgmenu",
  components: {
    regMenu,
    burger,
    Language,
  },
  data() {
    return {
      showSearch: false,
      openburger: false,
      menuShowID: -1,
      StatusMenu: true,
      show_lang: false,
      listIndex: 0,
      closeImg: require("@/static/img/close.png"),
      openImg: require("@/static/img/open.png"),
      menuInput: "",
      pcProListShow: false,
      greenIndex: 0,
      proList: [
        {
          title: "颈椎按摩",
          children: [
            {
              img: "https://files.skg.com/skg/2024-04-15/39c0dcee-f59d-484b-9e87-05c3855d9ac5.png",
              name: "K5三代尊贵款",
              path: "/proDetail/K53",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-15/b6e87cab-4ac7-4c28-ab9b-9bcc2f9c7cd8.png",
              name: "G7二代豪华款",
              path: "/proDetail/G72",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-17/7d194273-6561-4864-ab4b-8a0e995347bc.png",
              name: "BP3",
              path: "/proDetail/BP3",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-03-18/19a99858-ca9d-4952-9bce-c64ef2e2ec04.png",
              name: "P7",
              path: "/proDetail/P7",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-03-18/862e6e47-f735-4800-a0ad-f8c707cc7b45.png",
              name: "N5",
              path: "/proDetail/N5",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-03-18/92183c93-8ae7-49fa-a48a-8ec6702286a7.png",
              name: "K5Pro",
              path: "/proDetail/K5Pro",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2021-09-17/ddca1865-ca17-4cbe-93d7-82a3f146632e.png",
              name: "G7Pro",
              path: "/proDetail/G7PRO",
              params: "0",
            },
            // {img: "https://files.skg.com/skg/2022-11-17/a95d2821-eb9e-4187-9577-2bc2cd4ab684.png",name: "全新K5 尊贵款",path: "/proDetail/newK5Ultra",params:"0"},
            // {img: "https://img.skg.com/skg.com/20200723/696de2ea4b1f47e59899fc6bd23ced33.png",name: "4098蓝牙",path: "/proDetail/k5BLE",params:"0"},
            // {img: "https://img.skg.com/skg.com/20200723/aff2d99a1c01459780a3d1dc31ece6c3.png",name: "K6",path: "/proDetail/k6",params:"0"},
            {
              img: "https://files.skg.com/skg.com/20200918/3005687f26f24a1298c3f62803475984.png",
              name: "",
              path: "/proList",
              params: "",
            },
          ],
        },
        {
          title: "肩颈按摩",
          children: [
            {
              img: "https://files.skg.com/skg/2024-05-22/44324373-6bea-477c-88bf-6afe678563a4.png",
              name: "H5二代豪华款",
              path: "/proDetail/H52PRO",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-15/68fb72eb-ecca-4a93-9f90-1cf79214fd91.png",
              name: "H5豪华款",
              path: "/proDetail/H5PRO",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-03-20/4093c4ff-e161-4a3d-9d6c-60a0447fc2be.png",
              name: "H5舒享款",
              path: "/proDetail/H5",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-03-14/649401ad-0c2d-48d7-a1c7-6e50af2aa07a.png",
              name: "H3二代豪华款",
              path: "/proDetail/H3",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-05-17/ae59a518-1a17-4e2d-a404-ad4763d6c676.png",
              name: "肩颈按摩仪H7",
              path: "/proDetail/H7",
              params: "0",
            },
            {
              img: "https://img.skg.com/skg.com/20210525/c2c5c37bbadc4a598344306f56d03e45.png",
              name: "肩颈按摩仪T5",
              path: "/proDetail/T5",
              params: "0",
            },
          ],
        },
        {
          title: "眼部按摩",
          children: [
            {
              img: "https://files.skg.com/skg/2022-05-17/83917463-e382-46bf-a640-a9f135314cb1.png",
              name: "K5眼部按摩仪",
              path: "/proDetail/K5EYE",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-16/a2134f6d-4ec3-4f83-bf21-ca5e2886f193.png",
              name: "E3 二代",
              path: "/proDetail/E32",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-04-28/a28eda9b-874b-436d-bcc2-4e0245ae199c.png",
              name: "W3睡眠眼罩",
              path: "/proDetail/W3",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-04-28/fd499e70-e4a7-48a7-9e5e-8d786b4ad1aa.png",
              name: "E3PRO",
              path: "/proDetail/E3PRO",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-03-18/da941693-62b3-4312-8abe-f1cb17ac2731.png",
              name: "E5眼部按摩仪",
              path: "/proDetail/E5",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-03-18/d4d4d7e9-303e-4f97-b043-0c45a929721e.png",
              name: "E4Pro",
              path: "/proDetail/E4Pro",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg.com/20210113/69edfd59666640e6b9a23bc5e54662d1.png",
              name: "E4眼部按摩仪",
              path: "/proDetail/E4",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg.com/20200723/0b930f2f693d4fb7858f2f5d1827ec52.png",
              name: "4301眼部按摩仪",
              path: "/proDetail/4301",
              params: "0",
            },
          ],
        },
        {
          title: "腰部按摩",
          children: [
            {
              img: "https://files.skg.com/skg/2024-04-24/d9ed99e5-d497-4be7-b33d-68137a4c8364.png",
              name: "K5腰带二代",
              path: "/proDetail/K52W",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2023-12-20/a0ac9ce9-13f4-4831-84e8-860e5274c332.png",
              name: "G7 豪华款",
              path: "/proDetail/G7waist",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-15/a7329be2-b002-4dc2-828a-8e8cc1f34429.png",
              name: "K5",
              path: "/proDetail/K5",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-11-17/ed3de266-356e-44a7-ad06-d1edcf9b2d1c.png",
              name: "W7 尊贵款",
              path: "/proDetail/W7Ultra",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-05-17/46140348-fabd-4f7d-bfc4-4cea5b49d8d2.png",
              name: "小蛮腰",
              path: "/proDetail/XMY",
              params: "0",
            },
            {
              img: "https://img.skg.com/skg.com/20210716/1e46bc2295074417bedf7a5537b35353.png",
              name: "W5ST按摩腰带",
              path: "/proDetail/W5ST",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2021-09-17/c554a89a-15be-4caa-b30a-8da26c1dd45f.png",
              name: "W5TE按摩腰带",
              path: "/proDetail/W5TE",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2021-09-17/985832a2-5623-41b6-9c4f-a20d5a41300d.png",
              name: "BW3按摩腰带",
              path: "/proDetail/BW3",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-04-28/b1d0f5db-90d7-4ec7-8c46-649d1be1c727.png",
              name: "T5腰靠",
              path: "/proDetail/T5YK",
              params: "0",
            },
          ],
        },
        {
          title: "头部按摩",
          children: [
            {
              img: "https://files.skg.com/skg/2022-03-18/21a41c1e-1b89-4481-abeb-010b1f85560d.png",
              name: "BC3",
              path: "/proDetail/BC3",
              params: "0",
            },
          ],
        },
        {
          title: "腿膝按摩",
          children: [
            {
              img: "https://files.skg.com/skg/2022-03-18/ec5ff544-aef5-460d-bb1a-cf7cf6e9c72b.png",
              name: "BM3",
              path: "/proDetail/BM3",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-03-18/ec5ff544-aef5-460d-bb1a-cf7cf6e9c72b.png",
              name: "W3 二代",
              path: "/proDetail/W32",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2022-03-18/0a37abd9-9ddd-4e71-972f-23fe0ee31df2.png",
              name: "BK3",
              path: "/proDetail/BK3",
              params: "0",
            },
          ],
        },
        {
          title: "筋膜枪",
          children: [
            {
              img: "https://files.skg.com/skg/2021-10-21/a918df84-0a61-4034-b238-99c9295b56e1.png",
              name: "X7筋膜枪",
              path: "/proDetail/X7",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-16/142086b6-c6e5-45f2-826c-76e249789fb7.png",
              name: "F3二代筋膜枪",
              path: "/proDetail/F32",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg.com/20200910/f058ea6d3a6f44a9bfb0b7901ed2a82b.png",
              name: "F3筋膜枪",
              path: "/proDetail/F3",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg.com/20201230/b52d50839776433aa6163cbba570e9fc.png",
              name: "全新F5",
              path: "/proDetail/F5",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2021-09-17/d3b89e6a-4d18-4898-9aef-2c82dd0ea088.png",
              name: "F7筋膜枪",
              path: "/proDetail/F7",
              params: "0",
            },
          ],
        },
        {
          title: "健康监测手表",
          children: [
            {
              img: "https://files.skg.com/skg/2024-04-16/4793a4dc-fe8b-4bd7-95fd-23f999c8861b.png",
              name: "V9豪华款",
              path: "/proDetail/V9PRO",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-16/033e289f-8626-41c8-be16-fe766cef8396.png",
              name: "S7",
              path: "/proDetail/S7",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2024-04-16/ff86060d-2bcb-4cb6-8aa6-187f332cec75.png",
              name: "V7 二代",
              path: "/proDetail/V72",
              params: "0",
            },
            {
              img: "https://files.skg.com/skg/2023-04-27/33f59a67-8cf0-4095-a6be-09d6fd6653d8.png",
              name: "V9C",
              path: "/proDetail/V9C",
              params: "0",
            },
          ],
        },
      ],
      routerList: [
        {
          title: "技术原理",
          path: "/sooth",
        },
      ],
      childrenList: [
        {
          title: "购买渠道",
          childrenTxt: [
            { name: "线下体验店", path: "/store" },
            { name: "官方授权店", path: "/store" },
          ],
        },
        {
          title: "探索精彩SKG",
          childrenTxt: [
            { name: "关于SKG", path: "/about" },
            { name: "新闻中心", path: "/news" },
            { name: "加入我们", path: "/joinUs" },
            { name: "知识产权", path: "/patent" },
            { name: "假冒产品对策", path: "/countermove" },
          ],
        },
        {
          title: "商务合作",
          childrenTxt: [
            { name: "招商加盟", path: "/cooperation" },
            { name: "媒体联络", path: "/contactUs" },
            { name: "廉政条例", path: "/policy" },
            { name: "供应链合作", path: "/tenders" },
          ],
        },
        {
          title: "服务与支持",
          childrenTxt: [
            { name: "联系我们", path: "/contactUs" },
            { name: "服务政策", path: "/" },
            { name: "真伪查询", path: "/query" },
          ],
        },
      ],
      PCtop: [
        {
          title: "购买渠道",
          path: "/store",
        },
        {
          title: "探索精彩SKG",
          path: "/about",
        },
        {
          title: "商务合作",
          path: "/cooperation",
        },
        {
          title: "服务与支持",
          path: "/contactUs",
        },
      ],
      blueImg: [
        "https://files.skg.com/skg.com/20200918/72027c59ee894dbdb410bc24d622e639.png",
        "https://files.skg.com/skg.com/20201229/6da27c5f7bd742779467eb651411e18d.png",
        "https://files.skg.com/skg.com/20200918/66dc3277768840f0b3fb2c717b654e3a.png",
        "https://files.skg.com/skg.com/20200918/dc37679ad42b4234893a9fdb92b578d5.png",
        "https://files.skg.com/skg.com/20210331/13709839883a4b16a511a03fdf1935e1.png",
        "https://img.skg.com/skg.com/20210525/233ad2d91fd04974853617ded64a8e21.png",
        "https://files.skg.com/skg/2021-10-21/079cda1f-45ce-4871-bcb0-0767d5406c04.png",
      ],
      blackImg: [
        "https://files.skg.com/skg.com/20200917/2e68b91100774bea9748227b79ff94a4.png",
        "https://files.skg.com/skg.com/20201229/ea54d92ccc9b4dd18d953f0be6056bb4.png",
        "https://files.skg.com/skg.com/20200917/75b72577824243f28e468a99dfbf1a67.png",
        "https://files.skg.com/skg.com/20200917/d23a665fa66142f49d3c5c18f191e7f7.png",
        "https://files.skg.com/skg.com/20210331/edd7a5a503cf47e59d543902a83a80b5.png",
        "https://img.skg.com/skg.com/20210525/0555c5ee14e74a23beb3a42c82aecf46.png",
        "https://files.skg.com/skg/2021-10-21/68cf4102-c3d0-43f5-9c6e-4620a2f761d1.png",
      ],
      searchList: [],
      searchList2: [],
      productList: [
        {
          name: "K6颈椎按摩仪（炫彩浅蓝）",
          type: "k6",
        },
        {
          name: "K6颈椎按摩仪（炫彩浅粉）",
          type: "k6",
        },
        {
          name: "K6颈椎按摩仪（炫彩墨绿）",
          type: "k6",
        },
        {
          name: "K6颈椎按摩仪（炫彩蓝）",
          type: "k6",
        },
        {
          name: "K6颈椎按摩仪（炫彩浅银）",
          type: "k6",
        },
        {
          name: "4098PLUS颈椎按摩仪",
          type: "k5PLUS",
        },
        {
          name: "4098尊贵款颈椎按摩仪",
          type: "k5尊贵款",
        },
        {
          name: "4098经典颈椎按摩仪",
          type: "k5经典",
        },
        {
          name: "4098蓝牙颈椎按摩仪",
          type: "k5蓝牙",
        },
        {
          name: "4998AI颈椎按摩仪",
          type: "k5AI",
        },
        {
          name: "全新K5颈椎按摩仪",
          type: "newk5",
        },
        {
          name: "4356颈椎按摩仪",
          type: "k4",
        },
        {
          name: "K3颈椎按摩仪（蒂芙尼蓝）",
          type: "k3_1",
        },
        {
          name: "K3颈椎按摩仪（浅灰）",
          type: "k3_2",
        },
        {
          name: "K3颈椎按摩仪（象牙白）",
          type: "k3_3",
        },
        {
          name: "古力娜扎联名款（粉色）颈椎按摩仪",
          type: "dz_1",
        },
        {
          name: "古力娜扎联名款（白色）颈椎按摩仪",
          type: "dz_2",
        },
        {
          name: "施华洛世奇联名定制款颈椎按摩仪 ",
          type: "dz_3",
        },
        {
          name: "古力娜扎联名款（红色）颈椎按摩仪",
          type: "dz_4",
        },
        {
          name: "4301眼部按摩仪",
          type: "eyes_1",
        },
        {
          name: "4306眼部按摩仪",
          type: "eyes_2",
        },
      ],
      activeIndex: null,
      menuList: [
        {
          title: "购买渠道",
          children: [
            { name: "线下体验店", path: "store" },
            { name: "官方授权店", path: "store" },
          ],
        },
        {
          title: "探索精彩SKG",
          children: [
            { name: "关于SKG", path: "about" },
            { name: "新闻中心", path: "news" },
            { name: "加入我们", path: "joinUs" },
            { name: "知识产权", path: "patent" },
            { name: "假冒产品对策", path: "countermove" },
          ],
        },
        {
          title: "商务合作",
          children: [
            { name: "招商加盟", path: "cooperation" },
            { name: "媒体联络", path: "contactUs" },
            { name: "廉政条例", path: "policy" },
            { name: "供应链合作", path: "tenders" },
          ],
        },
        {
          title: "服务与支持",
          children: [
            { name: "联系我们", path: "contactUs" },
            { name: "真伪查询", path: "query" },
            {
              name: "在线咨询",
              path: "https://webchat-sh.clink.cn/chat.html?accessId=f044a176-831b-4e26-8be4-267884a2bb09&language=zh_CN",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({ testUrl: (state) => state.testUrl }),
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    openProDetail(path, type) {
      this.$store.commit("setProductFlag", !this.$store.state.productFlag);
      this.$store.commit("setProductParams", type);
      this.showSearch = false;
      this.openburger = false;
      // this.$router.push({name: path, params:{index: type}})
    },
    closes() {
      if (this.menuInput == "") {
        this.showSearch = false;
      }
    },
    openNewsdetail(name) {
      window.open(`${this.$store.state.urlNew}html/${name}`, "_blank");
    },
    openView(link) {
      // console.log("link",link)
      window.open(link, "_blank");
    },
    getSearchlist(likes) {
      const htpOpt = {
        url: `${this.$store.state.testUrl}web/serchGrobal/search?likes=${likes}`,
        // url: `https://cn.skg.com/service/web/serchGrobal/search?likes=${likes}`,
        // url:`http://10.102.104.151:8080/skg/web/serchGrobal/searchByLikes?likes=${likes}`,
        method: "get",
        // params:likes
      };
      this.$http(htpOpt).then((res) => {
        if (res.code == 0) {
          res.object.news.map((item, index) => {
            if (item.title.length > 20) {
              item.title = item.title.substring(0, 20) + "...【新闻】";
            } else {
              item.title = item.title + "【新闻】";
            }
          });
          this.searchList2 = res.object.news;
          // this.searchList = JSON.parse(decodeURIComponent(res.object.product[0].pcImageArray));
        }
      });
    },
    goto() {
      this.show_lang = !this.show_lang;
      // window.open("https://cn.skg.com")
    },
    clkHome() {
      this.showSearch = false;
      this.menuInput = "";
      var width = document.body.clientWidth;
      if (width < 768) {
        this.openMenu();
      }
      this.$router.push("/");
    },
    getDataList() {
      this.dataListLoading = true;
      let i = 1;
    },
    openMenu() {
      this.openburger = !this.openburger;
      this.menuShowID = -1;
      this.show_lang = false;
      this.showSearch = false;
      this.menuInput = "";
    },
    search() {
      this.showSearch = true;
      this.searchList = this.productList.filter(
        (item) => item.name.indexOf(this.menuInput.toUpperCase()) != -1
      );
      // var dataform = new FormData()
      this.getSearchlist(this.menuInput);
    },
    triggleMenu(index) {
      if (this.menuShowID > 0) {
        this.StatusMenu = false;
        setTimeout(() => {
          this.StatusMenu = true;
          this.menuShowID = -1;
        }, 600);
      } else this.menuShowID = index;
    },
    showPro(index, className) {
      var proDel = document.getElementsByClassName(className);
      var openImg = document.getElementsByClassName("openImg");
      if (
        proDel[index].style.display === "none" ||
        proDel[index].style.display === ""
      ) {
        proDel[index].style.display = "block";
        openImg[index].src = this.closeImg;
      } else {
        proDel[index].style.display = "none";
        openImg[index].src = this.openImg;
      }
    },
    showDel(index, className) {
      var proDel = document.getElementsByClassName(className);
      var titleBox = document.getElementsByClassName("childrenTit");
      var openImg = document.getElementsByClassName("childrenOpenImg");
      // console.log(proDel[index].style.display)
      if (
        proDel[index].style.display === "none" ||
        proDel[index].style.display === ""
      ) {
        proDel[index].style.display = "block";
        titleBox[index].style.borderBottom = "0.5px solid rgba(0,0,0,0.2)";
        openImg[index].src = this.closeImg;
      } else {
        proDel[index].style.display = "none";
        titleBox[index].style.borderBottom = "none";
        openImg[index].src = this.openImg;
      }
    },
    // pc端显示产品列表
    pcShowDetail(index) {
      this.show_lang = false;
      this.greenIndex = index;
      this.pcProListShow = true;
      this.listIndex = index;
      document.getElementsByClassName("banner")[0].style.background = "#fff";
      document.getElementsByClassName("pcListChildTit")[index].style.color =
        "#42d7c8";
    },
    // pc端隐藏产品列表
    pcHideDetail(index) {
      this.pcProListShow = false;
      document.getElementsByClassName("banner")[0].style.background = "#42d7c8";
      document.getElementsByClassName("pcListChildTit")[
        this.listIndex
      ].style.color = "#000";
    },
    showList() {
      this.pcProListShow = true;
      document.getElementsByClassName("banner")[0].style.background = "#fff";
      document.getElementsByClassName("pcListChildTit")[
        this.listIndex
      ].style.color = "#42d7c8";
    },
    hideList() {
      this.pcProListShow = false;
      document.getElementsByClassName("banner")[0].style.background = "#42d7c8";
      document.getElementsByClassName("pcListChildTit")[
        this.listIndex
      ].style.color = "#000";
    },
    getNews() {
      const htpOpt = {
        url: `${this.$store.state.testUrl}web/news/list`,
        method: "post",
        params: {
          page: 1,
          size: 1000,
        },
      };
      this.$http(htpOpt).then((res) => {
        if (res.code == 0) {
          this.searchData = res.page.list;
        }
      });
    },
  },
  // created() {
  //   this.getDataList();
  // },
  created() {
    this.getDataList();
  },
  mounted() {
    // var width = document.body.clientWidth
    // var logo = document.getElementById("logo")
    // // console.log(width)
    // if(width < 750){
    //   logo.style.left = (width - logo.offsetWidth)/2 + "px"
    // }
  },
};
</script>
<style lang="scss" scoped>
$hover-color: #ffd0a1;
$font-color: #000; //#afafaf;
$bg-color: #42d7c8;
.mobileLogo {
  display: none;
}
.mobileLangIcon {
  display: none;
}
.el-menu {
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: #fff !important;
    border: none;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
.banner {
  background: $bg-color;
  width: 1280px;
  padding: 50px 10px 10px 10px;
  box-sizing: border-box;
  position: relative;
  -webkit-transform: translateZ(0);
  top: 0;
  z-index: 10;
  cursor: pointer;
  &:hover,
  &.open {
    height: auto;
  }
  #searchList {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 50px;
    z-index: 999999999;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 10px;
    li {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #eee;
      padding-left: 15px;
      color: #000;
      overflow: hidden;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .level {
    height: 100vh;
  }
  .pcProList {
    z-index: 9999;
  }

  .language-icon {
    cursor: pointer;
    width: 20px;
    margin-left: 38px;
  }
  .box {
    width: 90%;
    margin-left: 5%;
    height: 1500px;
    background: gray;
    overflow: hidden;
  }
}
.logo {
  float: left;
  width: 128px;
  p {
    display: none;
  }
}
.menuInputBox {
  position: absolute;
  left: 70px;
  top: 10px;
  height: 30px;
  width: 70vw;
  border-radius: 4px;
  background: #fff url("../../../static/img/search.png") no-repeat;
  background-position: 5px center;
  background-size: 18px;
  #serText {
    color: #42d7c8;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 6px;
    z-index: 999;
  }
  .menuInput {
    height: 25px;
    width: 60vw;
    margin-left: 30px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 3px;
    z-index: 222;
    &::placeholder {
      color: #bcbcbc;
    }
  }
}
.level {
  float: right;
  background: #fff;
  ul {
    height: 2000px;
    overflow: scroll;
  }
  li {
    float: left;
    .title {
      height: 30px;
    }
    .titleImg {
      width: 62px;
      height: 16px;
      margin: 7px 0 0 50px;
    }
    .titleBox {
      width: 100%;
      height: 30px;
    }
    .proDel {
      width: 80vw;
      margin-left: 10vw;
      overflow: hidden;
      display: none;
    }
    .childrenTit {
      width: 75vw;
      height: 30px;
      margin-left: 50px;
      span {
        padding-left: 0px;
      }
    }
    .routerBox {
      width: 80vw;
      margin-left: 50px;
      overflow: hidden;
      display: none;
    }
    .routerBox .detailBox:nth-child(even) {
      margin-left: 20px;
    }
    .proBox {
      width: 50%;
      height: 185px;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .chidrenImg {
        width: 100px;
        height: 106px;
      }
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .childrenName {
        font-family: DFPGB_Y5;
        font-size: 12px;
        text-align: center;
        float: left;
      }
    }
    .detailBox {
      width: 130px;
      height: 45px;
      line-height: 45px;
      float: left;
      position: relative;
    }
    .openImg {
      width: 10px;
      height: 10px;
      position: absolute;
      top: 10px;
      right: 0;
      opacity: 0.4;
    }
    .toImg {
      width: 5px;
      height: 8px;
      right: 20px;
      top: 19px;
      position: absolute;
    }
    .titleTxt {
      font-family: DFPGB_Y5;
      font-size: 16px;
      padding-left: 50px;
    }
  }
}
.burger {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  cursor: pointer;
}
@media screen and (min-width: 220px) and (max-width: 767px) {
  .banner {
    background: $bg-color;
    width: 100vw;
    box-sizing: border-box;
    padding: 40px 0 10px 0;
    font-size: 14px;
    position: fixed;
    top: 0px;
    .bannerTop {
      display: none;
    }

    .mobileLogo {
      display: block;
    }
    .mobileLangIcon {
      display: block;
      width: 25px;
      position: absolute;
      top: 13px;
      right: 10px;
    }
    .reg,
    .level {
      display: none;
      overflow: scroll;
      position: absolute;
      top: 50px;
      padding-bottom: 80px;
    }
    .pcList {
      display: none;
    }
    .logo {
      left: 50% !important;
      transform: translateX(-50%);
      width: 74px;
      position: absolute;
      top: 13px;
      left: 125px;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        width: 300px;
        text-align: left;

        font-size: 12px;
        color: $font-color;
      }
      &.open {
        display: block;
        overflow: scroll;
      }
      img {
        width: 100%;
      }
    }
    .star {
      display: block;
      width: 20px;
      position: absolute;
      right: 53px;
      top: 15px;
    }
    &:hover {
      height: auto;
    }
    .burger {
      display: block;
    }
    .level {
      width: 100vw;
      text-align: left;
      max-height: 5000px;
      float: none;
      // overflow: hidden; //二级菜单隐藏
      animation: closeMenu 0.6s cubic-bezier(1.32, 1.67, 1.51, 1) forwards;
      li {
        float: none;
        position: relative;
        display: block;
        margin: 18px 0 0 0;
        line-height: 30px;
        width: 87vw; //二级菜单隐藏; //二级菜单隐藏
        background-size: 7px; //二级菜单隐藏
        background-position: 99% center; //二级菜单隐藏
        background-repeat: no-repeat; //二级菜单隐藏
        &:nth-child(1) {
          margin-top: 10px;
        }
      }
      &.open {
        display: block;
      }
    }
    // .pcProList {
    //   display: none;
    // }
  }
}
@media screen and (min-width: 768px) and (max-width: 1349px) {
  .banner {
    background: $bg-color;
    width: 100vw;
    box-sizing: border-box;
    padding: 45px 0 10px 0;
    font-weight: bold;
    font-size: 14px;
    position: fixed;
    top: 0px;
    .bannerTop {
      width: 100vw;
      height: 20px;
      background: #000;
      color: #fff;
      position: absolute;
      top: 0;
      .topMenu {
        height: 14px;
        margin-left: 2.5vw;
        li {
          float: left;
          padding: 0 5px;
        }
        .topTxt {
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .pcList {
      width: 100%;
      position: absolute;
      right: 0;
      top: 24px;
      display: flex;
      justify-content: space-around;
      height: 25px;
      .pcListChild {
        height: 30px;
        cursor: pointer;
        img {
          width: 100%;
        }
        .language-icon {
          width: 20px;
        }
        .pcListChildTit {
          height: 25px;
          font-size: 18px;
          font-family: HanSansCNMedium;
        }
      }
      .childTxt {
        width: auto;
      }
      .childTxt a {
        color: #000;
      }
    }
    .reg,
    .level {
      display: none;
      overflow: scroll;
      position: absolute;
      top: 50px;
      padding-bottom: 80px;
    }
    .logo {
      width: 74px;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        width: 300px;
        text-align: left;

        font-size: 12px;
        color: $font-color;
      }
      &.open {
        display: block;
        overflow: scroll;
      }
      img {
        width: 100%;
      }
    }
    .star {
      display: block;
      width: 20px;
      position: absolute;
      right: 53px;
      top: 15px;
    }
    &:hover {
      height: auto;
    }
    .burger {
      display: none;
    }
    .level {
      width: 100vw;
      text-align: left;
      max-height: 5000px;
      float: none;
      // overflow: hidden; //二级菜单隐藏
      animation: closeMenu 0.6s cubic-bezier(1.32, 1.67, 1.51, 1) forwards;
      li {
        float: none;
        position: relative;
        display: block;
        margin: 18px 0 0 0;
        line-height: 30px;
        width: 87vw; //二级菜单隐藏; //二级菜单隐藏
        background-size: 7px; //二级菜单隐藏
        background-position: 99% center; //二级菜单隐藏
        background-repeat: no-repeat; //二级菜单隐藏
        &:nth-child(1) {
          margin-top: 10px;
        }
      }
      &.open {
        display: block;
      }
    }
    .pcProList {
      // display: none;
      left: 0;
      width: 100vw;
      height: 27vw;
      background: rgba($color: #fff, $alpha: 0.95);
      position: fixed;
      top: 55px;
      .pcProMenu {
        height: 20vw;
        width: 100%;
        margin-top: 3vw;
        display: flex;
        justify-content: center;
        .pcProBox {
          width: 95px;
          height: 16vw;
          margin: 2vw 20px 0 0px;
          .pcChidrenImg {
            width: 95px;
            height: 100px;
          }
          .pcChildrenName {
            text-align: center;
            font-family: DFPGB_Y5;
            line-height: 20px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1350px) {
  .banner {
    background: $bg-color;
    width: 100vw;
    box-sizing: border-box;
    padding: 55px 0 10px 0;
    font-size: 14px;
    position: fixed;
    top: 0px;
    .bannerTop {
      width: 100vw;
      height: 20px;
      background: #000;
      color: #fff;
      position: absolute;
      top: 0;
      .topMenu {
        height: 14px;
        margin-left: 2.5vw;
        li {
          float: left;
          padding: 0 5px;
        }
        .topTxt {
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .pcList {
      width: 100%;
      position: absolute;
      right: 0;
      top: 27px;
      display: flex;
      justify-content: space-around;
      height: 25px;
      .pcListChild {
        float: left;
        height: 36px;
        cursor: pointer;
        img {
          width: 100%;
          margin-top: 2px;
        }

        .language-icon {
          cursor: pointer;
          width: 20px;
          margin-left: 38px;
        }
        .pcListChildTit {
          height: 25px;
          font-size: 20px;
          font-weight: bold;
          font-family: HanSansCNMedium;
        }
      }
      .childTxt {
        width: auto;
      }
      .childTxt a {
        color: #000;
      }
    }
    .reg,
    .level {
      display: none;
      overflow: scroll;
      position: absolute;
      top: 50px;
      padding-bottom: 80px;
    }
    .logo {
      width: 74px;
      margin: 0 40px;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        width: 300px;
        text-align: left;

        font-size: 12px;
        color: $font-color;
      }
      &.open {
        display: block;
        overflow: scroll;
      }
      img {
        width: 100%;
      }
    }
    .star {
      display: block;
      width: 20px;
      position: absolute;
      right: 53px;
      top: 15px;
    }
    &:hover {
      height: auto;
    }
    .burger {
      display: none;
    }
    .level {
      width: 100vw;
      text-align: left;
      max-height: 5000px;
      float: none;
      // overflow: hidden; //二级菜单隐藏
      animation: closeMenu 0.6s cubic-bezier(1.32, 1.67, 1.51, 1) forwards;
      li {
        float: none;
        position: relative;
        display: block;
        margin: 18px 0 0 0;
        line-height: 30px;
        width: 87vw; //二级菜单隐藏; //二级菜单隐藏
        background-size: 7px; //二级菜单隐藏
        background-position: 99% center; //二级菜单隐藏
        background-repeat: no-repeat; //二级菜单隐藏
        &:nth-child(1) {
          margin-top: 10px;
        }
      }
      &.open {
        display: block;
      }
    }
    .pcProList {
      // display: none;
      width: 100vw;
      height: 18vw;
      background: rgba($color: #fff, $alpha: 0.95);
      position: fixed;
      left: 0;
      top: 55px;
      .pcProMenu {
        height: 15vw;
        width: 100%;
        margin-top: 3vw;
        display: flex;
        justify-content: center;
        .pcProBox {
          width: 95px;
          height: 10vw;
          margin: 2vw 40px 0 40px;
          .pcChidrenImg {
            height: 100px;
          }
          .pcChildrenName {
            text-align: center;
            font-family: DFPGB_Y5;
            line-height: 20px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
