<template>
    <div class="reg">
        <ul>
            <li><router-link to="/distribution/distribution" target="_blank"  class="client">经销商登录</router-link></li>
        </ul>
    </div>
</template>
<style lang="scss">
$font-color: #afafaf;
.reg{
    ul{
        li{
            margin: 0;
            // margin: 0 0 0 10px;
            float: left;
            a{
                color: rgba(255, 255, 255, 0.7);
                padding-left: 20px;
                margin-left: 0px;
                // margin-left: 20px;
                &:hover{
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
        &:after{
            content: '';
            display: block;
            clear: both;
        }
    }
}
@media screen and (max-width: 420px) {
    .reg{
    ul{
        li{
            margin: 0;
            float: none;
            a{
                color: $font-color;
                padding-left: 0;
                margin-left: 0;
                &:hover{
                    color: $font-color;
                }
                &.client{
                    background: none;
                }
                &.user{
                    background: none;
                }
                &.reg{
                    background: none;
                }
            }
        }
        &:after{
            content: '';
            display: block;
            clear: both;
        }
    }
}
}

</style>

