import {createRouter, createWebHashHistory, createWebHistory} from "vue-router"

import wrap from "../components/Modules/template/home.vue";  //主页
import about from "../components/Modules/about/about.vue";   // 关于SKG
import store from "../components/Modules/store/store.vue";    // 查找体验店
import contactUs from "../components/Modules/contactUs/contactUs.vue";   // 联系我们
import joinUs from "../components/Modules/joinUs/joinUs.vue";   // 加入我们
import joinUsAll from "../components/Modules/joinUsAll/joinUsAll.vue";   // 加入我们所有列表
import cooperation from "../components/Modules/cooperation/cooperation.vue";   // 企业合作
import sooth from "../components/Modules/sooth/sooth.vue";   // 舒缓原理
import proDetail from "../components/Modules/proDetail/proDetail.vue";   // 产品详情
import query from "../components/Modules/query/query.vue";  // 真伪查询
import patent from "../components/Modules/patent/patent.vue";  // 知识产权
import news from "../components/Modules/news/news.vue";   // 新闻中心
import map from "../components/Modules/map/map.vue";   // 地图
import proList from "../components/Modules/proList/proList.vue";   // 产品列表
import tenders from "../components/Modules/tenders/tenders.vue";   // 产品列表
import tendersDetails from "../components/Modules/tenders/tendersDetails.vue";   // 产品列表
import authen from "../components/Modules/Authen/authen.vue";  //公众号菜单
import terminations from "../components/Modules/terminations/termination.vue";   // 除名查询
import countermove from "../components/Modules/countermove/countermove.vue";   // 除名查询
import productList from "../components/views/productList/index";   // 产品检索
import productDetail from "../components/views/productList/detail";   // 产品检索-移动详情
import unLoad from "../components/views/404.vue";   // 产品检索-移动详情
import policy from "../components/Modules/policy/policy.vue";   // 廉政条例

// 2. 定义路由配置
const routes = [
  {
    path: "/",
    name: "home",
    meta: { templateID: 3 },
    component: wrap
  },
  {
    path: "/tenders",
    name: "tenders",
    component: tenders
  },
  {
    path: "/tendersDetails",
    name: "tendersDetails",
    component: tendersDetails
  },
  {
    path: "/joinUs",
    name: "joinUs",
    component: joinUs
  },
  {
    path: "/authen",
    name: "authen",
    component: authen
  },
  {
    path: "/policy",
    name: "policy",
    component: policy
  },
  {
    path: "/terminations",
    name: "terminations",
    component: terminations
  },
  {
    path: "/map/:key?",
    name: "map",
    component: map
  },
  {
    path: "/joinUsAll",
    name: "joinUsAll",
    component: joinUsAll
  },
  {
    path: '/countermove',
    name: 'countermove',
    component: countermove
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: contactUs
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: cooperation
  },
  {
    path: "/news",
    name: "news",
    component: news
  },
  {
    path: "/about",
    name: "about",
    component: about
  },
  {
    path: "/store",
    name: "store",
    meta: { templateID: 13 },
    component: store
  },
  {
    path: "/sooth",
    name: "sooth",
    component: sooth
  },
  {
    path: "/proList",
    name: "proList",
    component: proList
  },
  {
    path: "/query",
    name: "query",
    component: query
  },
  {
    path: "/patent",
    name: "patent",
    component: patent
  },
  {
    path: "/proDetail/:id",
    name: "proDetail",
    component: proDetail
  },
  {
    path: '/productList',
    name: 'productList',
    component: productList
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: productDetail
  },
  {
    path: '/404',
    name: 'unLoad',
    component: unLoad
  }
]

// 3. 创建路由实例
const router = createRouter({
  // 4. 采用hash 模式
  // history: createWebHashHistory(),	
  // 采用 history 模式
  history: createWebHistory(),
  routes, // short for `routes: routes`
});
router.afterEach((to,from,next) =>{
  // console.log("afterEach")
  // window.scrollTo(0,0)
  // document.body.scrollTop = 0
  var scrollTop = document.body.scrollTop;
  document.body.style.marginTop = -scrollTop + 'px';
  document.body.scrollTop = 0;
  document.body.style.transition = 'all 1s ease-in-out';
  document.body.style.marginTop = 0;
  setTimeout(function () { document.body.style.transition = 'none'; }, 1000);
})
export default router