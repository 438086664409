<template>
  <div class="mySw full" >
    <swiper
      class="swiper-container"
      :autoplay="{ autoplay: true }"
      loop>
      <swiper-slide v-for="(item, i) in dataArr" :key="i">
        <div class="swiper-slide" :style="{'backgroundImage': 'url(' + item.bg_photo + ')'}"></div>
      </swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper> 
  </div>
</template>
<script>
import maindata from "../../../static/data/maindata"
import mainpcdata from "../../../static/data/mainpcdata"
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
//data
export default {
  name: "smallwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
     swiperOption: { //同swiper
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataArr: [],
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      // console.log(swiper);
    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  methods:{
    init(){

    }
  },
  created(){
    var width = document.body.clientWidth
    if(width > 600){
      this.dataArr = mainpcdata.data
    } else {
      this.dataArr = maindata.data
    }
    // console.log("maindata",this.dataArr)
  },
};
</script>
<style lang="scss" scoped>
// .swiper-pagination{
//   .swiper-pagination-bullet{
//   background: none;
//   border: 1px solid #fff;
  
// }
// .swiper-pagination-bullet-active{
//     background: #fff;
//     border: none;
//   }
// }
// .swiper-container{
//   margin-top: 50px;
// }

  .swiper-pagination {
    position: absolute;
    width: 100vw; 
    height: 2vh;
    bottom: 4vh;
    left: 0vw;
    --swiper-theme-color: #1F6162;
  }

.mySw{
  width: 100%;
  padding-top: 50px;
}
.swiper-container {
  width: 100%;
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #42d7c8;
  --swiper-navigation-color: #42d7c8;
  --swiper-navigation-size: 20px;
}
.swiper-slide {
  width: 100vw;
  height: calc(100vh - 20px);
  position: relative;
  // background-image: url($bgUrl);
  background-repeat: no-repeat;
  background-position:  center center;
  background-size: cover;
}
.swiper-slide img{
  width: 100%;
}


@media screen and (max-width: 600px) {
  .mySw {
    padding-top: 114px;
  }
  .swiper-slide {
    width: 100vw;
    height: 177vw;
    position: relative;
    // background-image: url($bgUrl);
    background: no-repeat 50% 50%;
    background-size: cover;
    .content {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      padding: 120px 100px;
      box-sizing: border-box;
      div {
        font-size: 28px;
        line-height: 38px;
        text-transform: Uppercase;
        text-align: left;
        color: #454545;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>


