<template>
<div class="mySw full" >
  <!-- Swiper -->
  
  <swiper
    class="swiper-container"
    :autoplay="{ autoplay: false }"
    loop>
    <swiper-slide>
      <router-link to="/news">
        <div class="title">新闻中心</div>
        <div class="more">了解更多</div>
        <img  src= "https://files.skg.com/skg/2023-02-27/6725144a-2c87-4417-9d86-b36f5bfece47.png"  alt="" />
      </router-link>
    </swiper-slide>
    <swiper-slide>
      <router-link to="/patent">
        <div class="title">知识产权及专利技术</div>
        <div class="more">了解更多</div>
        <img  src= "https://files.skg.com/skg/2023-02-27/4b87b611-8011-48b4-be4c-67aa8ca89369.jpg"  alt="" />
      </router-link>
    </swiper-slide>
  </swiper>

</div>
  
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
import maindata from "../../../static/data/maindata"
//setting
export default {
  name: "aboutSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true, // 是否循环轮播
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    }
  },
  props:{
    getdata:{
      type:Object,
      default:()=>maindata
    }
  },
  methods:{
    init(){

    }
  },
  created()
  {
    this.init()
  }
};
</script>
<style lang="scss" scoped>
.swiper-slide {
  width:100%;
  img {
    width: 100%;
  }
}

.mySw{
  width: 60vw;
  
  margin:  0 20vw;
}
.order {
  width: 100%;
  font-size: 18px;
  font-family: DFPGB_Y5;
  text-align: center;
}
.title {
  text-align: center;
  font-size: 36px;
  margin: 30px 0 20px 0;
}
.more {
  text-align: center;
  color: rgb(155, 151, 151);
  font-size: 20px;
  margin: 0 0 50px 0;
}
.content {
  width: 100%;
  font-size: 14px;
  font-family: DFPGB_Y5;
  text-align: left;
}
.swiper-slide  {
  width: 60vw;
  img {
    width: 100%;
  }
}
.swiper-button-next:after {
  content: none;
}
.swiper-button-prev:after {
  content: none;
}
</style>


