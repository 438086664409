<template>
  <div>
    <skgmenu/>
    <div class="policy">
			<div class="warp">
				<div class="title">采购相关————廉政条例</div>
				<div class="content">
					<b class="weight">1 廉政建设声明</b>
					<p>为了构建一个公平、公正、公开的合作平台和环境，SKG认为商业腐败行为影响市场公平竞争，对社会、经济以及企业的自身发展都有着十分恶劣的影响。
						SKG采取积极有效的措施，并建立相关管理体系，防范商业腐败行为。
						SKG要求所有SKG员工或代表SKG从事商业行为的实体和个人不得以任何直接或间接的方式向公职人员或其他实体和个人索贿以获取个人利益。</p>
					<p>如有合作伙伴基于同样目的向SKG员工作出上述行为，员工必须予以拒绝并向公司报告。在与合作伙伴展开业务合作时，
						SKG通过协议条款及相应的监控程序要求合作伙伴也遵从SKG的反腐败政策并对其行为进行约束</p>
					<b class="weight">2 廉政条例</b>
					<p>根据公司审计委员会要求，SKG制定了《反腐败政策管理规定》《举报人保护和奖励制度》《合作单位廉洁暨保密承诺书》，并定期对公司内部涉及采购行为的员工进行宣导和教育。
						该文件规定了须禁止的各种为了谋取不正当利益的腐败行为，同时也制定了对各种不同严重程度的腐败行为的处罚措施，以确保各种腐败行为能够得到相应的处罚。</p>
					<p class="margintop32">除此外，文件也规定了对合作伙伴的行贿行为处罚措施，具体措施如下：</p>
					<p>合作伙伴向我公司员工行贿，一经查实，SKG公司有权单方面与行贿方解除合同，
						停止与行贿方的一切合作以及通报其违规行为，并依法对行贿方采取诸如冻结所有应付账款的措施，
						同时行贿方还应向SKG公司支付10万元人民币作为违约金或者支付所涉订单（合同）金额的 30%作为违约金，
						两者以高者为准。如果行贿方未及时缴纳违约金，SKG公司有权从应支付行贿方的合同款项中直接扣除。对情节严重者，列入黑名单；触犯法律的，移交司法处理。</p>
					<p class="margintop32">为了能及时纠正不正之风，SKG鼓励公司同事和供应商去揭发和检举SKG内部员工的腐败行为，对积极检举者给予适当的奖励，奖励措施如下：</p>
					<p>A、举报我公司员工贪腐行为的，一经查实，依据查实的涉案金额等额给予举报人奖励，奖励金额最高100万元。</p>
					<p>B、举报我公司员工其它舞弊行为的（包括但不限于在业务上渎职，玩忽职守，徇私舞弊、滥用职权等违规行为），
						一经查实，由审计委员会酌情给予举报人奖励，奖励金额人民币10000元至100万元。</p>
					<b class="weight">3 举报途径</b>
					<p>如果贵司在与SKG合作的过程中遇到了一些非公平的待遇，严重影响到贵司与SKG的合作，可通过以下途径向SKG进行申诉举报：</p>
					<p>A、通过电子邮件举报：group@skg.com</p>
					<p>B、电话/企业微信：18924854001</p>
					<p>C、公众号：廉洁SKG</p>
					<img class="policyImg" src='https://files.skg.com/skg/2023-01-11/34f22111-96ba-4c7d-8ba4-be25530b85fc.png' alt="">
					<div class="imgTxt">SKG审计监察投诉受理</div>
				</div>
			</div>
		</div>
     <myfooter/>
  </div>
</template>
<script>
import skgmenu from "@/components/Modules/menu/menu.vue";
import myfooter from "@/components/Modules/footer/footer.vue";
export default {
  name: "temp",
  components: {
    skgmenu,
    myfooter,
  },
  data() {
    return {
      title: 'SKG联系方式_热线电话_客服_媒体联络_微博_公众号_商城-SKG官网',
      description: 'SKG联系方式,热线电话,客服,媒体联络,微博,公众号,商城。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。',
      Keywords: 'SKG联系方式,热线电话,客服,媒体联络,微博,公众号,商城',
    }
  },
  props:{

  },
  methods:{
  },
  mounted(){
    document.title= this.title
    document.querySelector('meta[name="Keywords"]').setAttribute('content', this.Keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', this.description)
  }
};
</script>

<style lang="scss" scoped>
.policy{
  padding-top: 30px;
  overflow-x: hidden;
	.warp {
		width: 100vw;
		padding: 0 10vw;
		box-sizing: border-box;
		margin: 0 auto;
		padding-bottom: 100px;
	}
	.title {
		font-size: 24px;
		margin: 100px 0 50px 0;
		text-align: center;
	}
	.content {
		font-size: 16px;
		.weight {
			padding: 35px 0 10px 0;
			font-weight: bold;
			display: block;
		}
		p {
			line-height: 32px;
			text-align: justify;
			margin: 0;
			padding: 0;
		}
		.margintop32 {
			margin: 32px 0 16px;
		}
		.policyImg {
			width: 150px;
		}
		.imgTxt {
			width: 150px;
			text-align: center;
			font-size: 12px;
		}
	}
}
@media screen and (min-width: 768px) {
.policy{
  padding-top: 60px;
  overflow-x: hidden;
	.warp {
		width: 660px;
		padding: 0 0;
		margin: 0 auto;
		padding-bottom: 100px;
	}
	.title {
		font-size: 36px;
		margin: 100px 0 50px 0;
		text-align: center;
	}
	.content {
		font-size: 16px;
		.weight {
			padding: 35px 0 10px 0;
			font-weight: bold;
			display: block;
		}
		p {
			line-height: 32px;
			text-align: justify;
			margin: 0;
			padding: 0;
		}
		.margintop32 {
			margin: 32px 0 16px;
		}
		.policyImg {
			width: 150px;
		}
		.imgTxt {
			width: 150px;
			text-align: center;
			font-size: 12px;
		}
	}
}
}

</style>

