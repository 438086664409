<template>
  <div >
    <swiper
      class="swiper-container"
      :navigation="{
        nextEl: '.swiper-view-next',
        prevEl: '.swiper-view-prev',
      }"
      :autoplay="{ autoplay: true }"
      loop>
      <swiper-slide v-for="(item, i) in videoData" :key="i">
        <div class="videoBox">
          <video
            class="indexVideo"
            controls="controls"
            :poster="item.poster"
          >
          <source :src="item.src" type="video/mp4">
          </video>
          <!-- <div class="videoBottom">
            <img :src="src.videoBottom" alt />
          </div> -->
        </div>
        </swiper-slide>
      <!-- <div class="swiper-button-next swiper-view-next"></div>
      <div class="swiper-button-prev swiper-view-prev"></div> -->
    </swiper> 
  </div>
</template>
<script>
//setting
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
export default {
  name: "videowiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
     swiperOption: { //同swiper
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataArr: [],

      src: {
        videoImg: require('@/static/img/videoImg.png'),
        videoBottom: require('@/static/img/videoBottom.png')
      },
      videoData: [
        {
          src: "https://files.skg.com/skg/2023-07-13/7f33b2c3-b100-479f-824f-6b22f3c9801c.mp4",
          poster: "https://files.skg.com/skg/2023-07-13/d1e03479-efc6-4f8c-99b7-a56797128e99.png",
        },
        {
          src: "https://files.skg.com/skg/2023-07-13/1d5320e1-32a6-4200-ada3-df462d05db90.mp4",
          poster:  "https://files.skg.com/skg/2023-07-13/e0dcc784-28a5-4cfa-aea0-b7dc44ea570e.png",
        },
        {
          src: "https://files.skg.com/skg/2023-07-13/f23b5027-114c-402f-a07b-892d4976c971.mp4",
          poster:  "https://files.skg.com/skg/2023-07-13/57f5ad76-239f-4f06-b673-57973990733d.png",
        }
      ],
    }
  },
  methods:{
    init(){

    },
  },
  created()
  {
    this.init()
  }
};
</script>
<style lang="scss" scoped>
  .mySw{
    width: 100%;
  }
.swiper-container {
  width: 100%;
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #42d7c8;
  --swiper-navigation-color: #42d7c8;
  --swiper-navigation-size: 20px;
}
  .indexVideo {
    width: 900px;
    height: 500px;
    margin: 0 auto;
  }
  .videoBox {
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .indexVideo {
    width: 59vw;
    height: 33vw;
  }
  .videoBottom{
    width: 59vw;
    height: 3vw;
    margin: 0 auto;
    margin-top: -2.5vw;
    text-align: center;
  }
@media screen and (min-width: 768px) {
  .mySw{
    width: 100%;
  }

  .indexVideo {
    width: 59vw;
    height: 33vw;
  }
  .videoBottom{
    width: 59vw;
    height: 3vw;
    margin: 0 auto;
    margin-top: -2vw;
  }
  .videoBottom img{
    height: 6vw;
  }
  .swiper-view-prev::after{
    width: 100px;
    height: 100px;
    margin-left: 60px;
    font-size: 60px;
  }
  .swiper-view-next::after{
    width: 100px;
    height: 100px;
    margin-right: 60px;
    font-size: 60px;
  }
}
@media screen and (max-width: 768px) {
  .swiper-view-prev,.swiper-view-next {
    display: none;
  }
  .videoBottom{
    width: 59vw;
    height: 3vw;
    margin: 0 auto;
    margin-top: -2vw;
  }
  .videoBottom img{
    width: 95%;
    height: 4vh;
  }
  .indexVideo {
    width: 90vw;
    height: 50vw;
  }
}
</style>


