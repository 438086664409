<template>
  <div style="overflow-x:hidden">
		<skgmenu />
			<div class="tenders">
				<div class="tenders-warp">
					<div class="tenders-tit">
						招标公告
					</div>
					<div class="tenders-search">
						<span>公告标题</span>
						<input class="searchInput" v-model="queryTitle" placeholder="请输入公告标题" type="text">
						<button class="searchBtn" @click="queryData">查询</button>
					</div>
					<el-table
						:data="tendersList"
						style="width:100vw"
						@sort-change="sortChange"
						@row-click="rowClick"
						>
						<el-table-column
							prop="title"
							label="公告标题">
						</el-table-column>
						<el-table-column
							prop="createTime"
							width="125"
							label="发布日期"
							sortable="custom">
							 <template v-slot="scope">
								{{scope.row.createTime && scope.row.createTime.split(" ")[0]}}
							</template>
						</el-table-column>
						<el-table-column
							prop="deadline"
							width="125"
							label="截止日期"
							sortable="custom">
							 <template v-slot="scope">
								{{scope.row.deadline && scope.row.deadline.split(" ")[0]}}
							</template>
						</el-table-column>
					</el-table>
					<div style="float:right">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="pageNo"
							:page-sizes="[1, 5, 10, 20]"
							:page-size="pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="totalCount">
						</el-pagination>
					</div>
				</div>
			</div>
		<myfooter />
  </div>
</template>
<script>
import skgmenu from '@/components/Modules/menu/menu.vue'
import myfooter from '@/components/Modules/footer/footer.vue'
export default {
  name: 'tenders',
  components: {
    skgmenu,
    myfooter
  },
  data() {
    return {
      title: 'SKG招标公告',
      description: 'SKG招标公告',
      Keywords: 'SKG招标公告',
      tendersList: [],
			queryTitle: '',
			totalCount: 0,
			pageNo: 1,
			columnWidth: 120,
			titleWidth: 500,
			pageSize: 5,
			createTimeSortType: '',
			deadlineSortType: ''
    }
  },
  mounted(){
    document.title= this.title
    document.querySelector('meta[name="Keywords"]').setAttribute('content', this.Keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', this.description)
		this.getTenders()
		let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		this.columnWidth = clientWidth < 600 ? '10%' :  '25%'
		this.titleWidth = clientWidth < 600 ? '10%' :  '50%'
		// console.log("columnWidth", this.columnWidth)
  },
  methods: {
    getTenders(){
      let that = this
			// console.log("href",window.location.href)
			let href = window.location.href
			let url = href.indexOf("cn.skg.com") > -1 ? 'https://lubanactivityapi.skg.com': 'http://skg.devdev.cn:28180'
      const htpOpt = {
        url: url + '/commonSvc/api/v1/official-website/list',
        method: "get",
        params:{
          pageNo: that.pageNo,
          pageSize: that.pageSize,
					column: 'createTime,deadline',
					order: that.createTimeSortType +','+that.deadlineSortType,
					title: '*'+that.queryTitle+'*',
        }
      };
      this.$http(htpOpt).then(res => {
				// console.log("res",res.result)
        if(res.code==200){
          that.tendersList = res.result.records;
          that.totalCount = res.result.total
        }
      })
    },
		rowClick(row) {
			// console.log("row",row.id)
			this.$router.push({name: 'tendersDetails',query: {id: row.id}})
		},
		handleSizeChange(val) {
			this.pageSize = val
		},
		handleCurrentChange(val,e) {
			this.pageNo = val
		},
		sortChange(e){
			// console.log("sortChange",e)
			if(e.prop === 'createTime'){
				this.createTimeSortType = (e.order === null ? '' : (e.order === 'descending' ? 'desc' : 'asc'))
			}
			if(e.prop === 'deadline'){
				this.deadlineSortType = (e.order === null ? '' :(e.order === 'descending' ? 'desc' : 'asc'))
			}
		},
		queryData(){
			this.getTenders()
		}
	},
}
</script>

<style lang="scss" scoped>
.tenders {
	margin-top: 50px;
	background-color: #F4F7FB;
	padding: 33px 0;
	min-height: 60vh;
	.tenders-warp {
		width: 1080px;
		margin: 0 auto;
		background-color: #FFF;
		.tenders-tit {
			height: 60px;
			border-bottom: 1px solid #eee;
			line-height: 60px;
			font-size: 20px;
			font-weight: bold;
			padding-left: 30px;
			&:before{
				content: "|";
				color: #42d7c8;
				width: 3px;
				height: 18px;
				background-color: #42d7c8;
			}
		}
		.tenders-search {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: left;
			align-items: center;
			padding-left: 30px;
			span {
				font-size: 16px;
				color: #000;
			}
			.searchInput {
				width: 218px;
				height: 31px;
				border: 1px solid #000;
				border-radius: 5px;
				margin-left: 5px;
				padding-left: 10px;
			}
			.searchBtn {
				width: 53px;
				height: 30px;
				background-color: #42d7c8;
				border-radius: 5px;
				color: #fff;
				line-height: 30px;
				text-align: center;
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.tenders {
		margin-top: 50px;
		background-color: #F4F7FB;
		padding: 33px 0;
		min-height: 60vh;
		.tenders-warp {
			width: 1080px;
			margin: 0 auto;
			background-color: #FFF;
			.tenders-tit {
				height: 60px;
				border-bottom: 1px solid #eee;
				line-height: 60px;
				font-size: 20px;
				font-weight: bold;
				padding-left: 30px;
				&:before{
					content: "|";
					color: #42d7c8;
					width: 3px;
					height: 18px;
					background-color: #42d7c8;
				}
			}
			.tenders-search {
				width: 100%;
				height: 90px;
				display: flex;
				justify-content: left;
				align-items: center;
				padding-left: 10px;
				span {
					font-size: 16px;
					color: #000;
				}
				.searchInput {
					width: 158px;
					height: 31px;
					border: 1px solid #000;
					border-radius: 5px;
					margin-left: 5px;
					padding-left: 10px;
				}
				.searchBtn {
					width: 53px;
					height: 30px;
					background-color: #42d7c8;
					border-radius: 5px;
					color: #fff;
					line-height: 30px;
					text-align: center;
					margin-left: 10px;
					cursor: pointer;
				}
			}
		}
	}
}
</style>

