const maindata = {
  data: [
    {
      bg_photo: "https://files.skg.com/skg/2023-02-10/13ba2985-1b32-4897-8f66-e82f0dace718.png", // 1
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2023-02-10/1821ad1e-3380-497e-aabc-f5c527fda899.jpg", // 1
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2023-02-10/f6993c8a-f27e-4ac0-85dd-d4083921a8fe.jpg", // 2
      url: "/",
    }
  ]
};
export default maindata;
