<template>
<div class="mySw full" >
  <swiper
    class="swiper-container"
    direction='vertical'
    :autoplay="{ autoplay: false }">
    <swiper-slide v-for="(item, i) in imgUrl" :key="i">
      <img :src="item" alt />
    </swiper-slide>
  </swiper> 
</div>
  
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
export default {
  name: "aboutSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      imgUrl: [
        'https://files.skg.com/skg.com/20210129/8fc683344ca54f2cb5299ece93cb8ed3.png',
        'https://files.skg.com/skg.com/20200818/8eb7bfc45cea49deb4d9135abc1e3f31.jpg',
      ]
    }
  },
  methods:{
    init(){

    }
  },
  created()
  {
    this.init()
  }
};
</script>
<style lang="scss" scoped>

.mySw{
  width: 100vw;
  height: 60vw;
  position: relative;
}
.swiper-slide {
  height: 60vw;
}
.swiper-pagination {
  position: absolute;
  top: 50%;
  right: 20px;
}
</style>


