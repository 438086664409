<template>
  <div style="overflow-x:hidden">
		<skgmenu />
			<div class="tenders">
				<div class="tenders-details-warp">
					<div class="tenders-details-top">
						<div class="tenders-details-title">{{dataInfo.title}}</div>
						<div class="tenders-details-createtime">发布时间：{{dataInfo.createTime}}</div>
					</div>
					<div class="tenders-details-content" v-html="dataInfo.content">

					</div>
				</div>
			</div>
		<myfooter />
  </div>
</template>
<script>
import skgmenu from '@/components/Modules/menu/menu.vue'
import myfooter from '@/components/Modules/footer/footer.vue'
export default {
  name: 'tendersDetails',
  components: {
    skgmenu,
    myfooter
  },
  data() {
    return {
      title: 'SKG招标公告',
      description: 'SKG招标公告',
      Keywords: 'SKG招标公告',
      tendersList: [],
			totalCount: 0,
			dataInfo: {},
			id: 0
    }
  },
  mounted(){
    document.title= this.title
    document.querySelector('meta[name="Keywords"]').setAttribute('content', this.Keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', this.description)
		this.id = this.$route.query.id
		// console.log("id",this.$route.query.id)
		this.getTenders()
  },
  methods: {
    getTenders(){
      let that = this
			let href = window.location.href
			let url = href.indexOf("cn.skg.com") > -1 ? 'https://lubanactivityapi.skg.com': 'http://skg.devdev.cn:28180'
      const htpOpt = {
        url: url + `/commonSvc/api/v1/official-website/queryById`,
        method: "get",
        params:{
					id: that.id
        }
      };
      this.$http(htpOpt).then(res => {
				// console.log("res",res.result)
        if(res.code==200){
          that.dataInfo = res.result
        }
      })
    },
		rowClick(row) {
			// console.log("row",row.id)
		},
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			// console.log(`当前页: ${val}`);
		}
	},
}
</script>

<style lang="scss" scoped>
.tenders {
	margin-top: 50px;
	background-color: #F4F7FB;
	padding: 33px 0;
	min-height: calc(100vh - 450px);
	.tenders-details-warp {
		width: 1080px;
		margin: 0 auto;
		background-color: #FFF;
		.tenders-details-top {
			width: 1000px;
			height: 180px;
			margin: 0 auto;
			border-bottom: 1px solid #eee;
			.tenders-details-title {
				text-align: center;
				font-size: 33px;
				font-weight: bold;
				line-height: 96px;
			}
			.tenders-details-createtime {
				text-align: center;
				font-size: 18px;
				color: #1E1E1E;
			}
		}
		.tenders-details-content {
			padding: 20px 40px;
		}
	}
}
@media screen and (max-width: 768px) {
.tenders {
	width: 100vw;
	margin-top: 50px;
	background-color: #F4F7FB;
	padding: 33px 0;
	min-height: calc(100vh - 450px);
	.tenders-details-warp {
		width: 100vw;
		margin: 0 auto;
		background-color: #FFF;
		.tenders-details-top {
			width: 100vw;
			height: 90px;
			margin: 0 auto;
			border-bottom: 1px solid #eee;
			.tenders-details-title {
				text-align: center;
				font-size: 33px;
				font-weight: bold;
				line-height: 48px;
			}
			.tenders-details-createtime {
				text-align: center;
				font-size: 12px;
				color: #1E1E1E;
			}
		}
		.tenders-details-content {
			padding: 20px 20px;
		}
	}
}
}
</style>

