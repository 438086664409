<template>
<div class="mySw full" >
  <swiper
    class="swiper-container"
    :pagination="{ clickable: true }"
    :autoplay="{ autoplay: true }"
    loop>
    <swiper-slide v-for="(item, i) in imgUrl" :key="i">
      <img :src="item" alt />
    </swiper-slide>
  </swiper> 
</div>
  
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
export default {
  name: "aboutSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
     swiperOption: { //同swiper
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      imgUrl: [
        'https://files.skg.com/skg.com/20210128/85dcd8636d7c4354920aaffb2d619fa9.jpg',
        'https://files.skg.com/skg.com/20210128/397063adfa1044f2bcb3e45483ca46e2.jpg',
        'https://files.skg.com/skg.com/20210128/2cb06ef96bce483abca6a1ca0375b1c7.jpg',
        'https://files.skg.com/skg.com/20210128/89b3f7c06ece441a9d6e89c4abb7a593.jpg',
      ]
    }
  },
  methods:{
    init(){

    }
  },
  created()
  {
    this.init()
  }
};
</script>
<style lang="scss" scoped>
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 0;
  outline: none
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 0;
  outline: none
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 22px;
}
.swiper-button-prev, .swiper-button-next {
  top: 200px;
  color: #ddd;
}
.swiper-slide {
  width:100%;
  img {
    width: 100%;
  }
}

.swiper-container {
  width: 100%;
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #42d7c8;
  --swiper-navigation-color: #42d7c8;
  --swiper-navigation-size: 20px;
}
.mySw{
  width: 100vw;
  margin:  0 ;
}
.order {
  width: 100%;
  font-size: 18px;
  font-family: DFPGB_Y5;
  text-align: center;
}
.content {
  width: 100%;
  font-size: 14px;
  font-family: DFPGB_Y5;
  text-align: left;
}
</style>


