<template>
  <div class="prolist">
  <skgmenu />
    <div class="title">
      <img src="https://img.skg.com/skg.com/20200723/d976c296db814cceb726f012ffc1a0cb.png" alt="">
    </div>
    <div class="prolistData">
      <swiper
        class="swiper-container"
        :slidesPerView="3"
        :spaceBetween="10"
        :autoplay="false">
        <swiper-slide class="proListChild swiper-slide" v-for="(Item,Index) of proListData1" :key="Index"  @click="openProDetail(Item.params)">
          <router-link :to="{path:Item.path, query:{index:Item.params}}">
            <img :src="Item.img" alt="">
            <p style="text-align:center">{{Item.name}}</p>
          </router-link>
        </swiper-slide>
      </swiper> 
    </div>

    <div class="title">
      <img src="https://img.skg.com/skg.com/20200723/40097cc7fb654a57b7cf6f3a764583ce.png" alt="">
    </div>
    <div class="prolistData">
      <!-- <div class="swiper-wrapper">
        <div class="proListChild swiper-slide" v-for="(childItem,childIndex) of proListData2" :key="childIndex" @click="openProDetail(childItem.params)" >
          <router-link :to="{path:childItem.path, query:{index:childItem.params}}">
            <img :src="childItem.img" alt="">
            <p style="text-align:center">{{childItem.name}}</p>
          </router-link>
        </div>
      </div> -->
      
      <swiper
        class="swiper-container"
        :slidesPerView="3"
        :spaceBetween="10"
        :autoplay="false">
        <swiper-slide class="proListChild swiper-slide" v-for="(Item,Index) of proListData2" :key="Index"  @click="openProDetail(Item.params)">
          <router-link :to="{path:Item.path, query:{index:Item.params}}">
            <img :src="Item.img" alt="">
            <p style="text-align:center">{{Item.name}}</p>
          </router-link>
        </swiper-slide>
      </swiper> 
    </div>

    <div class="title">
      <img src="https://img.skg.com/skg.com/20200723/075077d2332a475d9297d8b5ef03fdd5.png" alt="">
    </div>
    <div class="prolistData">
      <!-- <div class="swiper-wrapper">
        <div class="proListChild swiper-slide" v-for="(threeitem,threeIndex) of proListData3" :key="threeIndex" @click="openProDetail(threeitem.params)">
          <router-link :to="{path:threeitem.path, query:{index:threeitem.params}}">
            <img :src="threeitem.img" alt="">
            <p style="text-align:center">{{threeitem.name}}</p>
          </router-link>
        </div>
      </div> -->
      <swiper
        class="swiper-container"
        :slidesPerView="3"
        :spaceBetween="10"
        :autoplay="false">
        <swiper-slide class="proListChild swiper-slide" v-for="(Item,Index) of proListData3" :key="Index"  @click="openProDetail(Item.params)">
          <router-link :to="{path:Item.path, query:{index:Item.params}}">
            <img :src="Item.img" alt="">
            <p style="text-align:center">{{Item.name}}</p>
          </router-link>
        </swiper-slide>
      </swiper> 
    </div>

    <div class="title">
      <img src="https://img.skg.com/skg.com/20200723/bc11e3abadf5490b8940f8770cfd7d0c.png" alt="">
    </div>
    <div class="prolistData">
      <!-- <div class="swiper-wrapper">
        <div class="proListChild swiper-slide" v-for="(fourItem,fourIndex) of proListData4" :key="fourIndex" @click="openProDetail(fourItem.params)">
          <router-link :to="{path:fourItem.path, query:{index:fourItem.params}}">
            <img :src="fourItem.img" alt="">
            <p style="text-align:center">{{fourItem.name}}</p>
          </router-link>
        </div>
      </div> -->
      <swiper
        class="swiper-container"
        :slidesPerView="3"
        :spaceBetween="10"
        :autoplay="false">
        <swiper-slide class="proListChild swiper-slide" v-for="(Item,Index) of proListData4" :key="Index"  @click="openProDetail(Item.params)">
          <router-link :to="{path:Item.path, query:{index:Item.params}}">
            <img :src="Item.img" alt="">
            <p style="text-align:center">{{Item.name}}</p>
          </router-link>
        </swiper-slide>
      </swiper> 
    </div>

    <div class="title">
      <span>联名定制系列</span>
    </div>
    <div class="prolistData">
      <!-- <div class="swiper-wrapper">
        <div class="proListChild swiper-slide" v-for="(fiveItem,fiveIndex) of proListData5" :key="fiveIndex" @click="openProDetail(fiveItem.params)">
          <router-link :to="{path:fiveItem.path, query:{index:fiveItem.params}}">
            <img :src="fiveItem.img" alt="">
            <p style="text-align:center">{{fiveItem.name}}</p>
          </router-link>
        </div>
      </div> -->
      <swiper
        class="swiper-container"
        :slidesPerView="3"
        :spaceBetween="10"
        :autoplay="false">
        <swiper-slide class="proListChild swiper-slide" v-for="(Item,Index) of proListData5" :key="Index"  @click="openProDetail(Item.params)">
          <router-link :to="{path:Item.path, query:{index:Item.params}}">
            <img :src="Item.img" alt="">
            <p style="text-align:center">{{Item.name}}</p>
          </router-link>
        </swiper-slide>
      </swiper> 
    </div>

    <myfooter />
  </div>
</template>
<script>
//setting
import skgmenu from '@/components/Modules/menu/menu.vue'
import myfooter from '@/components/Modules/footer/footer.vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay, } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
export default {
  name: 'proList',
  components: {
    skgmenu,
    myfooter,
    Swiper,
    SwiperSlide,
  },
  data(){
    return {
      title: '【颈椎按摩仪】护颈仪_颈部颈椎按摩器_种类大全_好用吗_哪个好-SKG官网',
      description: '颈椎按摩仪好用吗?哪个好?SKG颈椎按摩仪,颈椎按摩器,颈部按摩器,护颈仪种类齐全,有K3,K4,K5,K6系列,联名定制系列等,任您选!',
      Keywords: 'SKG,颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩仪,颈椎按摩器好用吗,哪个好',
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      swiperOptionTwo: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      swiperOptionThree: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      swiperOptionFour: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      swiperOptionFive: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      proListData1: [
            {img: "https://img.skg.com/skg.com/20200723/aff2d99a1c01459780a3d1dc31ece6c3.png",name: "炫彩浅蓝",path: "/proDetail/k6",params:"0"},
            {img: "https://img.skg.com/skg.com/20200723/9de1ee250d3e4e868ccb169c3b0205ee.png",name: "炫彩浅粉",path: "/proDetail/k6",params:"1"},
            {img: "https://img.skg.com/skg.com/20200723/cfd3e8d29dc946ebacb74054adf2c493.png",name: "炫彩墨绿",path: "/proDetail/k6",params:"2"},
            {img: "https://img.skg.com/skg.com/20200723/072f8c740bb44daabed9c8f3c41c7e77.png",name: "炫彩蓝",path: "/proDetail/k6",params:"3"},
            {img: "https://img.skg.com/skg.com/20200723/f9208f03b0d244fb8e676a2426d05764.png",name: "炫彩浅银",path: "/proDetail/k6",params:"4"},
          ],
      proListData2:  [
            {img: "https://files.skg.com/skg.com/20200723/eac60efb2d2f4280a47c917ed88111f5.png",name: "全新K5",path: "/proDetail/newk5",params:"0"},
            {img: "https://files.skg.com/skg/2022-11-17/a95d2821-eb9e-4187-9577-2bc2cd4ab684.png",name: "全新K5 尊贵款",path: "/proDetail/newK5Ultra",params:"0"},
            {img: "https://files.skg.com/skg.com/20210331/98b9bb67e91d45418b745cd191ca991a.png",name: "K5mini",path: "/proDetail/K5mini",params:"0"},
            {img: "https://img.skg.com/skg.com/20200723/4987f07536ab4abb80d510f88cf3c3bd.png",name: "4098经典",path: "/proDetail/k5Classic",params:"0"},
            {img: "https://img.skg.com/skg.com/20200723/696de2ea4b1f47e59899fc6bd23ced33.png",name: "4098蓝牙",path: "/proDetail/k5BLE",params:"0"},
          ],
      proListData3: [
            {img: "https://files.skg.com/skg.com/20200908/a55d218c7f204477b2524d854f2ac8e1.png",name: "全新K4",path: "/proDetail/newK4",params:"0"},
            {img: "https://files.skg.com/skg.com/20200723/01bc7542ff72459793f6c1e39bb56af0.png",name: "4356",path: "/proDetail/k4",params:"0"},
          ],
      proListData4: [
            // {img: "https://files.skg.com/skg.com/20200723/d457e50c684d48fbaa6a988be73d89b2.png",name: "蒂芙尼蓝",path: "/proDetail/k3_1",params:"0"},
            {img: "https://files.skg.com/skg.com/20200723/a09b56d1a5a4408bb626bd642131e520.png",name: "浅灰",path: "/proDetail/k3_2",params:"0"},
            {img: "https://files.skg.com/skg.com/20200723/6d7a65cdd778433c80b64c86351fec12.png",name: "象牙白",path: "/proDetail/k3_3",params:"0"},
          ],
      proListData5:[
            {img: "https://files.skg.com/skg.com/20200723/8d287563d2b94e3abe7d8bdf4730735f.png",name: "娜小古定制款（粉色）",path: "/proDetail/dz_1",params:"0"},
            {img: "https://files.skg.com/skg.com/20200723/acd62874e1c64a67a8c3aa40b33ff599.png",name: "娜小古定制款（白色）",path: "/proDetail/dz_2",params:"0"},
            {img: "https://files.skg.com/skg.com/20200723/0a585859614546a493aa3ca0be5519ee.png",name: "施华洛世奇--水晶限量款",path: "/proDetail/dz_3",params:"0"},
            {img: "https://files.skg.com/skg.com/20200723/afb7c3e9f6194128987b5a43fd2abbde.png",name: "娜小古定制款（红色）",path: "/proDetail/dz_4",params:"0"},
          ],
    }
  },
  methods: {
    openProDetail(type){
      // console.log("123213213")
      this.$store.commit('setProductFlag',!this.$store.state.productFlag)
      this.$store.commit('setProductParams',type)
      this.showSearch = false;
      this.openburger = false;
      // this.$router.push({name:'proDetail', params:{index: type}})
    },
  },
  mounted(){
    document.title= this.title
    document.querySelector('meta[name="Keywords"]').setAttribute('content', this.Keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', this.description)
  },


}
</script>
<style lang="scss" scoped>
.swiper-container {
  width: 915px;
}
@media screen and (min-width: 0px) and (max-width: 768px){
  .prolist {
    padding-top: 50px;
    width: 100vw;
    .title {
      width: 100vw;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 90px;
      }
    }
    .prolistData {
      width: 100vw;
      height: 200px;
      display: flex;
      .swiper-slide {
        width: 150px;
        box-sizing: border-box;
        height: 180px;
        float: left;
        margin-left: 10px;
        border-radius: 10px;
        background: #F2F2F2;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        img {
          width: 84px;
          height: 90px;
          margin: 16px;
        }
        p {
          font-family: DFPGB_Y5;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 3920px){
  .prolist {
    padding-top: 65px;
    width: 99vw;
    .title {
      width: 99vw;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 90px;
      }
      span {
        font-family: DFPGB_Y5;
        font-size: 24px;
      }
    }
    .prolistData {
      width: 100vw;
      height: 225px;
      &:nth-last-child(2){
        margin-bottom: 50px;
      }
      .swiper-wrapper {
        display: flex;
        justify-content: center;
        .swiper-slide {
          width: 150px !important;
          cursor: pointer;
          box-sizing: border-box;
          height: 230px;
          float: left;
          margin-left: 10px;
          border-radius: 10px;
          background: #F2F2F2;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
          img {
            width: 114px;
            height: 124px;
            margin: 16px;
          }
          p {
            font-size: 16px;
            font-family: DFPGB_Y5;
            line-height: 24px;
          }
        }
      }
    }
  }

}
</style>
