const maindata = {
  data: [
    {
      bg_photo: "https://files.skg.com/skg/2023-02-10/7701dbf6-0ce0-426f-acf4-b69aa460695d.jpg", // 1
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2023-02-10/89013389-bf6f-4dc6-ac0f-b08745307af5.jpg", // 2
      url: "/",
    },
    {
      bg_photo: "https://files.skg.com/skg/2023-02-10/6dfbe69a-2fdc-4e8e-a95f-eff3e1e378d6.jpg",  // 3
      url: "/",
    }
  ]
};
export default maindata;
